<template>
	<div class="clients">
		<h1>Edit client</h1>
		<!-- GET CLIENT FORM -->
		<AddClientForm @clientUpdated="clientUpdated"></AddClientForm>
        <p v-for="message in formMessages" :key="message">
            {{message}}
        </p>
	<!-- GET USERS ASSIGNED LIST --> 
		<UsersList :key="usersListKey" @userRemoved="refreshUsersList"></UsersList>
		<!-- <Authentication></Authentication> -->
		<div class="client-button__wrapper">
			<div v-if="can(getAuthData, 'delete_global_client') && getAuthData.username !== getUserUsername">
				<button class="button button--delete-client" v-on:click="deleteClient()">Delete Client</button>
			</div>
			<div v-if="can(getAuthData, 'delete_global_client_permanently') && getAuthData.username !== getUserUsername">
				<button class="button button--delete-client mt" v-on:click="deleteClientPermanently()">Permanently Delete Client</button>
			</div>
		</div>
	</div>
</template>
<script>
import AddClientForm from "../../components/forms/AddClientForm.vue"
import UsersList from "../../components/lists/UsersList.vue"
import {mapGetters} from "vuex"
// import Authentication from "../../components/authentication/Authentication.vue";

export default {
	data() {
		return {
			usersListKey: 1,
      		formMessages: [],
		}
	},
	components: {
		AddClientForm,
		UsersList,
		// Authentication
	},
	methods: {
        clientUpdated(messages){
            this.formMessages = messages;
            this.refreshUsersList();
        },
		refreshUsersList() {
			this.usersListKey++
		},
		async deleteClient(){
			let confirmed = confirm(`Are you sure you want to delete client?`);
			if(confirmed){
				this.$store.dispatch('client/updateClientDeletedAt', this.getClientData);
			}
		},
		async deleteClientPermanently(){
			let confirmed = confirm(`Are you sure you want to permanently delete client?`);
			if(confirmed){
				this.$store.dispatch('client/deleteAccountData', this.getClientData);
			}
		}
	},
	computed: {
		...mapGetters("auth", ["getAuthData"]),
		...mapGetters("user", ["getUserUsername"]),
		...mapGetters("client", ["getClientData"]),
	},
}
</script>
